@import url(https://fonts.googleapis.com/css?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css?family=Aclonica&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background-image: url(/static/media/Background_Coral.e7042ad8.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size:contain;
  height:75vh;
  display:grid;
  grid-template-columns: 1fr 4fr;
  font-family: 'Quicksand',Arial, sans-serif;
  line-height: 1.3em;
  letter-spacing: 0.04em;
}
.side{
width:12vw;
}

.main{
  overflow: scroll;
  overflow-x: hidden;
}

p{
  color: rgba(0, 0, 0, 0.7);
 font-weight: normal;
}


/*//////////////// Media Query/////////////////*/


/* @media (max-width:992px){
  .App{
    height:100%;
    display: flex;
    flex-direction: column;
  }
  h2{
    font-weight: bold;
  }
  p{
    font-weight: bolder;
  }
  .main{
    margin-top: -40px;
    overflow-y: hidden;
  }
}

@media (max-width:769px){
  .App{
     display: flex;
    flex-direction: column;

  }
  .main{
    overflow: auto;
    overflow-x: hidden;
  }

} */

.sidebar{
    height:100vh;
    /* margin-top: -50px; */
    width:12vw;
    /* background-color:#f2f3f7; */
    /* overflow-y: scroll; */
     text-align: center;
}
.sidebar > h1 {
    padding-top: 20px;
    /* margin-left: 55px; */
    text-align: center;
}

.h1_links{
  text-decoration: none;
  font-family: 'Aclonica', sans-serif;
  color:#000;
  font-size:1.1em; 
}
.sidebar > p {
    font-size: 1em; 
    color:rgb(179, 179, 179);
    font-family: 'Comfortaa', cursive;
    font-weight: bold;
    font-size: larger;
    margin-top: 5px;
    margin-left: 55px;
    text-align: left;
}

.sidebar-nav{
    margin-left: 15px;
    margin-top: 100px;
    text-align: left;
}
.sidebar-nav-items{
    font-size:.8em; 
    margin-bottom: 5px;
}
.sidebar-nav-social{
  text-align: left;
  list-style: none;
  margin-left:  55px;
  margin-top: -7px;
}
.sidebar-nav-social-items{
  margin-right: 10px;
  width:2em;
  height:2em;
}
.sidebar-logo{
  width: 5em;
  height: 5em;
  /* border-radius: 50%; */
  margin-top: 40px;
}
.links{
  text-decoration: none;
  /* font-family: 'Quicksand', sans-serif; */
  font-family: 'Comfortaa', cursive;
  color:rgb(68, 68, 68);
  font-weight: bold;
  font-size: larger;
  line-height: 1.4rem;
}
/* .sidebar-nav-icons{
    margin-bottom: 15px;
} */

/* .flip-card-back{
  margin-top:50px;
}
.fa-lg{
  text-decoration: none;
  color:red;
} */

.links:hover{
 /* color:rgb(121, 189, 252); */
 color:rgb(179, 179, 179);
 /*text-decoration: underline;*/
}
.fas:hover{
  color:rgb(105, 33, 33);
  text-decoration: underline;
 }
ul{
   list-style:none;
 }

  .h1_links:hover{
  color: #000;
 }
 /*//////////////media ////////////////////*/
 /* @media (max-width:980px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:29px;
  }
 }


@media (max-width:768px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:28px;
  }
  .links{
display:none;
  }
  .fas{
    transform: scale(1.3);
    margin:5px 0px;
  }
  .title{
    font-size:16px;  
    font-weight: normal;
  }
 .gmail{
   font-size:16px;  
   font-weight: normal;
 }
}
 
@media (max-width:370px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:24px;
  }
  .links{
    font-size: 16px;
  }
  .fas{
    transform: scale(1.1);
    margin:5px 0px;
  }

} */
.About_box__2iRF7{
    margin-top:500px;
    margin-bottom: 50px;
    width: 54vw;
    /* background-color: rgb(185, 185, 185); */
    display:flex;
}

.About_About__1dBX1{
    margin-left:0;
    padding:0;
}
.About_profileimg__2ns_c{
    /* float:left; */
    margin-left:40px;
    max-width: 300px;
    max-height: 300px;
    border: 5px solid #000000;
}
.About_text__3d2ki{
    margin-left: 40px;
    margin-right: 40px;
}
.About_About__1dBX1 > p{
    color: rgb(0, 0, 0);
    font-size: .9em;
    padding:0;
    margin-top:0;
    margin-right:20px;
    margin-bottom: 20px;
 
}
/* .head{
    display:block;
    padding:0px;
    color: rgb(0, 0, 0);
    margin-bottom: 20px;
    letter-spacing: 5px;
    font-size: 20px;
} */

/* .heading {  
    display: block;
    padding:0px 12px;
    font-size: 18px;
    font-weight: 700;
    margin-left:30px;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 1.8;
} */
/* .label {
    position: relative;
    background: #f2f3f7;
    padding: 1.5em;
    margin-left: 60px;
}
.label h2 {
    font-size: 20px;
    font-family: "Quicksand", Arial, sans-serif;
    font-weight: 500;
   
}
.label p {
    margin-left: 200px;
   
} */

  
  /* @keyframes text{
    from{transform: translateX(-30px);
        opacity:0.5;
    }
  }
  

  @media (max-width:992px){
    .box{
        text-align: center;
    }
} */
.WorkImages_work_grid__3JPVg{
  margin-top: 5vh;
  margin-left: 5px;
  display:grid;
  grid-template-columns: repeat(2, 40%);
  grid-column-gap: 4em; 
  grid-row-gap: .5em; 
}
.WorkImages_grid_item__22mve{
  /* position:relative; */
  width: 34.5vw;
  height: 36vh;
  overflow: hidden;
}
.WorkImages_image__2Mvwh{
  /* margin-top: -10px; */
  width: 37vw;
  height: 33vh;
  object-fit:cover;
  object-position:center;
  
}

.WorkImages_caption__2-ShO{
  margin-top: .5vh;
  font-size: 1em;
  color:rgb(0, 0, 0);
  font-family: 'Comfortaa', cursive;
  font-weight: bold;
  line-height: 1rem;
}

.WorkImages_h1__2Lrkb{
    margin:50px;
    font-size: 10rem;
    font-family: 'Permanent Marker', cursive;
    position:fixed;
    top:-270px;
    left:10px;
    color:rgb(255, 0, 0)
  }
  
  .WorkImages_h2__3FbwO{
    margin:50px;
    font-size: 2rem;
    font-family: 'Permanent Marker', cursive;
    -webkit-animation-name: WorkImages_text__3JN7Z;
            animation-name: WorkImages_text__3JN7Z;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    position: relative;
    top:-470px;
    left:-20px;
    text-align: right;
    color:black;
  }

  .WorkImages_h1__2Lrkb a{
    color:rgba(255, 255,255, 0.8);
    width: 170px;
    margin:50px;
    font-size: 1.1rem;
    font-family: 'Permanent Marker', cursive;
    -webkit-animation-name: WorkImages_text__3JN7Z;
            animation-name: WorkImages_text__3JN7Z;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    position: relative;
    top:20px;
    left:-7px;
    color:rgba(255, 255,255, 0.8);
    padding: 11px 24px;
    box-shadow: 0px 10px 30px 0px rgba(21, 10, 82, 0.35);
    border-radius:22px;
    background-image:linear-gradient(to left,rgba(75,19,79,0.8) ,rgba(201,75,75,0.6));
  }
  .WorkImages_h2__3FbwO a{
    color:rgba(255, 255,255, 0.8);
    width: 170px;
    margin:50px;
    font-size: 1.1rem;
    font-family: 'Permanent Marker', cursive;
    -webkit-animation-name: WorkImages_text__3JN7Z;
            animation-name: WorkImages_text__3JN7Z;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    position: relative;
    top:20px;
    left:-7px;
    color:rgba(255, 255,255, 0.8);
    padding: 11px 24px;
    box-shadow: 0px 10px 30px 0px rgba(21, 10, 82, 0.35);
    border-radius:22px;
    background-image:linear-gradient(to left,rgba(75,19,79,0.8) ,rgba(201,75,75,0.6));
  }

  @-webkit-keyframes WorkImages_text__3JN7Z{
    from{-webkit-transform: translateY(20px);transform: translateY(20px);
    opacity: 0.2;}
    to{opacity: 1;}
  }

  @keyframes WorkImages_text__3JN7Z{
    from{-webkit-transform: translateY(20px);transform: translateY(20px);
    opacity: 0.2;}
    to{opacity: 1;}
  }
  
  /*@media (max-width:400px){
    .image{
      height:600px;
    }
    .h1 a{
      width: 120px;
      font-size: 0.8rem;
      margin:20px;
      padding: 9px 18px;
    }
    .h2 a{
      width: 120px;
      font-size: 0.8rem;
      margin:20px;
      padding: 9px 18px;
      position: relative;
    top:20px;
    left:17px;
    }
  }*/
.Resume_box__3rEbS{
    margin-top: 50px;
    margin-bottom: 20px;
    width: 54vw;
    display:flex;
    /* background-color: pink; */
    /* box-shadow: 10px 10px 20px rgba(244,241,241,1) ; */
    /* border-radius:6px; */
    /* animation-name: text;
    animation-duration: 7s;
    animation-timing-function: ease-out; */
}
.Resume_Resume__28Zm3{
    /* background-color:rgba(220, 220,220, 0.3); */
    margin-top:-15px;
    /* padding:20px; */
    display:flex;
    /* grid-template-columns:repeat(2,1fr); */
    grid-column-gap: 2em;
    -webkit-column-gap: 2em;
            column-gap: 2em; 
    width: 54vw;
}
.Resume_section__FOjPA{
    flex-grow:1;
}
.Resume_resumelist__1NGhj{
    margin-bottom:50%;
    padding:0;
    list-style-type:none;
    color: rgb(0, 0, 0);
    font-size: .9em;
}

/* .head{
    display: inline-block;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    margin-bottom: 20px;
    letter-spacing: 5px;
    font-size: 15px;
    font-weight: bold;
    font-size: larger;
    font-family: 'Comfortaa', cursive;
} */

.Resume_heading__2Cpp8 {  
    font-size: 1em;
    letter-spacing: 4px;
    line-height: 1.8;
}
/* div>h3{
        font-size: 20px;
        font-weight: 900;
        text-transform: uppercase;
        margin: 0px 0 0px 0;
} */
/* div>p{
    font-size: 18px;
} */
/* .web{
    height: 100%;
    height:180px;
   border:1px  black;
   border-bottom:2px solid #2c98f0;
   text-align: center;
   padding:1.2rem;
   box-shadow:  0px 0px 56px -8px rgba(0, 0, 0, 0.17) ;
    
}
.app{
        height:180px;
    height: 100%;
    border:1px  black;
   border-bottom:2px solid #f9bf3f ;
   text-align: center;
   box-shadow: 10px 10px 20px rgba(244,241,241,1) ;
    position: relative;
    top:-20px;
    padding:1.2rem;
}
.other{
    height: 100%;
    height:180px;
   border:1px  black;
   padding:1.2rem;
   border-bottom:2px solid #2fa499;
   text-align: center;
   box-shadow: 10px 10px 20px rgba(244,241,241,1) ;

} */

/* @keyframes text{
    from{transform: translateX(-30px);
        opacity:0;
    }
  } */

/*///////// Media Query //////////////*/
/* @media (max-width:1027px){
    
    .Interest {
        grid-template-columns: 1fr;
    }
    .web{
        margin-bottom:25px
    }
    .app{
        margin-bottom:25px
    }
    .other{
        margin-bottom:25px
    }
}


@media (max-width:769px){
    .box{
        text-align: center;
    }
    .Interest {
        grid-template-columns: 1fr;
    }
    .app{
        margin-top:20px;
        height:100%;
         position: static;
     }
     .other{
        margin-top:20px;
        height:180px; 
     }
}

@media (max-width:392px){
    .Interest {
        grid-template-columns: 1fr;
    }
    .app{
         position: static;
     }
} */
.Contact_box__2sc8i{
    display: inline-block;
    vertical-align:middle;
    width: 54vw;
    height: 8vh;
    /* background-color:rgb(177, 177, 177); */
    text-align: center;
    /* border: 2px solid #bebebe; */
}
.Contact_head__14ext{
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 4px;
    line-height: 1.8em;
}
.Contact_email__3nVfI{
    font-size: 1em;
    color: rgb(0, 0, 0);
}
