.box{
    display: inline-block;
    vertical-align:middle;
    width: 54vw;
    height: 8vh;
    /* background-color:rgb(177, 177, 177); */
    text-align: center;
    /* border: 2px solid #bebebe; */
}
.head{
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 4px;
    line-height: 1.8em;
}
.email{
    font-size: 1em;
    color: rgb(0, 0, 0);
}