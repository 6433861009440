@import url('https://fonts.googleapis.com/css?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css?family=Comfortaa:400&display=swap');
.sidebar{
    height:100vh;
    /* margin-top: -50px; */
    width:12vw;
    /* background-color:#f2f3f7; */
    /* overflow-y: scroll; */
     text-align: center;
}
.sidebar > h1 {
    padding-top: 20px;
    /* margin-left: 55px; */
    text-align: center;
}

.h1_links{
  text-decoration: none;
  font-family: 'Aclonica', sans-serif;
  color:#000;
  font-size:1.1em; 
}
.sidebar > p {
    font-size: 1em; 
    color:rgb(179, 179, 179);
    font-family: 'Comfortaa', cursive;
    font-weight: bold;
    font-size: larger;
    margin-top: 5px;
    margin-left: 55px;
    text-align: left;
}

.sidebar-nav{
    margin-left: 15px;
    margin-top: 100px;
    text-align: left;
}
.sidebar-nav-items{
    font-size:.8em; 
    margin-bottom: 5px;
}
.sidebar-nav-social{
  text-align: left;
  list-style: none;
  margin-left:  55px;
  margin-top: -7px;
}
.sidebar-nav-social-items{
  margin-right: 10px;
  width:2em;
  height:2em;
}
.sidebar-logo{
  width: 5em;
  height: 5em;
  /* border-radius: 50%; */
  margin-top: 40px;
}
.links{
  text-decoration: none;
  /* font-family: 'Quicksand', sans-serif; */
  font-family: 'Comfortaa', cursive;
  color:rgb(68, 68, 68);
  font-weight: bold;
  font-size: larger;
  line-height: 1.4rem;
}
/* .sidebar-nav-icons{
    margin-bottom: 15px;
} */

/* .flip-card-back{
  margin-top:50px;
}
.fa-lg{
  text-decoration: none;
  color:red;
} */

.links:hover{
 /* color:rgb(121, 189, 252); */
 color:rgb(179, 179, 179);
 /*text-decoration: underline;*/
}
.fas:hover{
  color:rgb(105, 33, 33);
  text-decoration: underline;
 }
ul{
   list-style:none;
 }

  .h1_links:hover{
  color: #000;
 }
 /*//////////////media ////////////////////*/
 /* @media (max-width:980px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:29px;
  }
 }


@media (max-width:768px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:28px;
  }
  .links{
display:none;
  }
  .fas{
    transform: scale(1.3);
    margin:5px 0px;
  }
  .title{
    font-size:16px;  
    font-weight: normal;
  }
 .gmail{
   font-size:16px;  
   font-weight: normal;
 }
}
 
@media (max-width:370px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:24px;
  }
  .links{
    font-size: 16px;
  }
  .fas{
    transform: scale(1.1);
    margin:5px 0px;
  }

} */