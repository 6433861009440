.box{
    margin-top:500px;
    margin-bottom: 50px;
    width: 54vw;
    /* background-color: rgb(185, 185, 185); */
    display:flex;
}

.About{
    margin-left:0;
    padding:0;
}
.profileimg{
    /* float:left; */
    margin-left:40px;
    max-width: 300px;
    max-height: 300px;
    border: 5px solid #000000;
}
.text{
    margin-left: 40px;
    margin-right: 40px;
}
.About > p{
    color: rgb(0, 0, 0);
    font-size: .9em;
    padding:0;
    margin-top:0;
    margin-right:20px;
    margin-bottom: 20px;
 
}
/* .head{
    display:block;
    padding:0px;
    color: rgb(0, 0, 0);
    margin-bottom: 20px;
    letter-spacing: 5px;
    font-size: 20px;
} */

/* .heading {  
    display: block;
    padding:0px 12px;
    font-size: 18px;
    font-weight: 700;
    margin-left:30px;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 1.8;
} */
/* .label {
    position: relative;
    background: #f2f3f7;
    padding: 1.5em;
    margin-left: 60px;
}
.label h2 {
    font-size: 20px;
    font-family: "Quicksand", Arial, sans-serif;
    font-weight: 500;
   
}
.label p {
    margin-left: 200px;
   
} */

  
  /* @keyframes text{
    from{transform: translateX(-30px);
        opacity:0.5;
    }
  }
  

  @media (max-width:992px){
    .box{
        text-align: center;
    }
} */