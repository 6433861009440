.box{
    margin-top: 50px;
    margin-bottom: 20px;
    width: 54vw;
    display:flex;
    /* background-color: pink; */
    /* box-shadow: 10px 10px 20px rgba(244,241,241,1) ; */
    /* border-radius:6px; */
    /* animation-name: text;
    animation-duration: 7s;
    animation-timing-function: ease-out; */
}
.Resume{
    /* background-color:rgba(220, 220,220, 0.3); */
    margin-top:-15px;
    /* padding:20px; */
    display:flex;
    /* grid-template-columns:repeat(2,1fr); */
    column-gap: 2em; 
    width: 54vw;
}
.section{
    flex-grow:1;
}
.resumelist{
    margin-bottom:50%;
    padding:0;
    list-style-type:none;
    color: rgb(0, 0, 0);
    font-size: .9em;
}

/* .head{
    display: inline-block;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    margin-bottom: 20px;
    letter-spacing: 5px;
    font-size: 15px;
    font-weight: bold;
    font-size: larger;
    font-family: 'Comfortaa', cursive;
} */

.heading {  
    font-size: 1em;
    letter-spacing: 4px;
    line-height: 1.8;
}
/* div>h3{
        font-size: 20px;
        font-weight: 900;
        text-transform: uppercase;
        margin: 0px 0 0px 0;
} */
/* div>p{
    font-size: 18px;
} */
/* .web{
    height: 100%;
    height:180px;
   border:1px  black;
   border-bottom:2px solid #2c98f0;
   text-align: center;
   padding:1.2rem;
   box-shadow:  0px 0px 56px -8px rgba(0, 0, 0, 0.17) ;
    
}
.app{
        height:180px;
    height: 100%;
    border:1px  black;
   border-bottom:2px solid #f9bf3f ;
   text-align: center;
   box-shadow: 10px 10px 20px rgba(244,241,241,1) ;
    position: relative;
    top:-20px;
    padding:1.2rem;
}
.other{
    height: 100%;
    height:180px;
   border:1px  black;
   padding:1.2rem;
   border-bottom:2px solid #2fa499;
   text-align: center;
   box-shadow: 10px 10px 20px rgba(244,241,241,1) ;

} */

/* @keyframes text{
    from{transform: translateX(-30px);
        opacity:0;
    }
  } */

/*///////// Media Query //////////////*/
/* @media (max-width:1027px){
    
    .Interest {
        grid-template-columns: 1fr;
    }
    .web{
        margin-bottom:25px
    }
    .app{
        margin-bottom:25px
    }
    .other{
        margin-bottom:25px
    }
}


@media (max-width:769px){
    .box{
        text-align: center;
    }
    .Interest {
        grid-template-columns: 1fr;
    }
    .app{
        margin-top:20px;
        height:100%;
         position: static;
     }
     .other{
        margin-top:20px;
        height:180px; 
     }
}

@media (max-width:392px){
    .Interest {
        grid-template-columns: 1fr;
    }
    .app{
         position: static;
     }
} */